.header-row-likes {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px #cccccc;
  max-height: 76px;
  height: 100%;
}

.header-row-exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-row-report-header:hover {
  cursor: pointer;
}

.header-row-exit:hover {
  cursor: pointer;
}

.label-likes {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4f576d;
  margin-top: 1%;
}

.img-exit {
  width: 5px;
  height: 5px;
  object-fit: contain;
}

.row-likes {
  display: flex;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;

  gap: 22px;
}

.user-name-likes {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
}

.col-likes {
}

.col-row-likes {
  width: 70%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.reaction-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;
  border-bottom: 1px solid #d0d3dd;
  height: 76px;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.reaction-modal-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  color: #4f576d;

  span {
    font-family: Rubik;
    line-height: 2rem;
  }
}

.reaction-modal-filter-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 76px;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;

  /* overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

.reaction-modal-filter-container::-webkit-scrollbar {
  display: none;
}

.reaction-modal-badge-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  height: fit-content;
  padding: 8px 20px 8px 20px;
  cursor: pointer;
}

.reaction-modal-badge-filter-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  height: fit-content;
  padding: 8px 18px;
}

.reaction-modal-badge-filter-name {
  font-family: Rubik;
  font-size: 14px;
}

.reaction-modal-content-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  max-height: 518px;
  height: 100%;
}

.reaction-modal-content-counter {
  display: flex;
  width: 100%;
}

.reaction-modal-content-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.reaction-modal-content-list {
  height: 100%;
}

.reaction-modal-counter-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  gap: 5px;
  padding-bottom: 20px;

  font-family: Rubik;
  font-size: 16px;
  color: #4f576d;
}

.reaction-modal-content-notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-family: Rubik;
  font-size: 20px;
  color: #808080;
}

.reaction-modal-pagination-container {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid #d0d3dd; */

  flex-direction: row;
}

.reaction-modal-pagination-container .container .ul-pagination .pagination {
  margin-bottom: 0;
}

.reaction-modal-pagination-container
  .container
  .ul-pagination
  .size-pagination {
  margin-top: 2px;
}

.reaction-modal-list-content {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reaction-modal-list-content:not(:last-child) {
  border-bottom: 1px solid #d0d3dd;
}

.reaction-modal-list-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reaction-modal-list-user-info h5 {
  font-family: Rubik;
  font-size: 14px;
  margin-bottom: 0px !important;
  color: #4f576d;
}

.reaction-modal-list-user-info span {
  font-family: Rubik;
  font-size: 12px;
  margin-bottom: 0px !important;
  color: #98a0b4;
}

.reaction-modal-list-name-sector-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 5px;
}

.reaction-modal-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
  gap: 8px;
  background: #f4f6fb;
  border-bottom: 1px solid #d0d3dd;
}

.scroll-btn {
  border: none;
  cursor: pointer;
  /* position: absolute; */
  background: none;
  padding: 0;
  top: 50%;
  z-index: 10;

  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-btn.left {
  left: 20px;
  transform: rotateY(180deg);
  padding: 0;
}

.scroll-btn.right {
  right: 20px;
  padding: 0;
}

.scroll-btn.right img {
  width: 8px;
}

.scroll-btn.left img {
  width: 8px;
}
